import * as React from "react"
import { Container } from "react-bootstrap"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faMusic } from "@fortawesome/free-solid-svg-icons"
import logo from "../images/logo.svg"
import { graphql, Link, useStaticQuery } from "gatsby"
import { formatLink } from "../helpers/formatLink"
import facebook from "../images/icons/facebook.svg"
import instagram from "../images/icons/instagram.svg"
import itunes from "../images/icons/itunes.svg"
import spotify from "../images/icons/spotify.svg"
import twitter from "../images/icons/twitter.svg"
import youtube from "../images/icons/youtube.svg"

const Header = () => {
  const data = useStaticQuery(graphql`
    query Socials {
      cockpitSocials {
        Youtube {
          value
        }
        Twitter {
          value
        }
        Spotify {
          value
        }
        Instagram {
          value
        }
        Facebook {
          value
        }
        AppleMusic {
          value
        }
      }
    }
  `)
  const {
    AppleMusic,
    Twitter,
    Facebook,
    Instagram,
    Spotify,
    Youtube,
  } = data.cockpitSocials
  return (
    <header>
      <h1>Hooverphonic timeline</h1>
      <Container>
        <div className="header-wrapper">
          <div className="logo">
            <img src={logo} alt="" />
          </div>
          <div className="links">
            {Facebook && (
              <Link
                to={formatLink(Facebook.value)}
                target="_blank"
                rel="noreferrer"
              >
                <img className="social-icon" src={facebook} alt="facebook icon" />
              </Link>
            )}
            {Twitter && (
              <Link
                to={formatLink(Twitter.value)}
                target="_blank"
                rel="noreferrer"
              >
                <img className="social-icon" src={twitter} alt="twitter icon" />
              </Link>
            )}
            {Instagram && (
              <Link
                to={formatLink(Instagram.value)}
                target="_blank"
                rel="noreferrer"
              >
                <img className="social-icon" src={instagram} alt="instagram icon" />
              </Link>
            )}
            {Youtube && (
              <Link
                to={formatLink(Youtube.value)}
                target="_blank"
                rel="noreferrer"
              >
                <img className="social-icon" src={youtube} alt="youtube icon" />
              </Link>
            )}
            {Spotify && (
              <Link
                to={formatLink(Spotify.value)}
                target="_blank"
                rel="noreferrer"
              >
                <img className="social-icon" src={spotify} alt="spotify icon" />
              </Link>
            )}
            {AppleMusic && (
              <Link
                to={formatLink(AppleMusic.value)}
                target="_blank"
                rel="noreferrer"
              >
                <img className="social-icon" src={itunes} alt="itunes icon" />
              </Link>
            )}
          </div>
        </div>
      </Container>
    </header>
  )
}

export default Header
