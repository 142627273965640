/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import * as React from "react"
import PropTypes from "prop-types"

import Header from "./Header"
import "bootstrap/dist/css/bootstrap-grid.min.css"
import "../styles/style.scss"
import { GatsbyImage } from 'gatsby-plugin-image';
import { Container } from 'react-bootstrap';

const Layout = ({ children, backgroundImage, onBackgroundLoad }) => {
  return (
    <>
      <Header />
      <GatsbyImage
        className="background-image"
        image={backgroundImage}
        alt=""
        onLoad={onBackgroundLoad}
      />
      <div>
        <main>{children}</main>
      </div>
      <div class="footer">
        <Container>
          <p>Made by <a href="https://www.xlab.be/en">XLab</a>.</p>
        </Container>
      </div>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
